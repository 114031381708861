import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"
import PreviewProvider from "./src/context/PreviewContext"

import { client } from "./src/apollo/client"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <PreviewProvider>{element}</PreviewProvider>
  </ApolloProvider>
)
