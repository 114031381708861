// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-bot-js": () => import("./../src/templates/bot.js" /* webpackChunkName: "component---src-templates-bot-js" */),
  "component---src-templates-feedback-js": () => import("./../src/templates/feedback.js" /* webpackChunkName: "component---src-templates-feedback-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-internal-js": () => import("./../src/templates/internal.js" /* webpackChunkName: "component---src-templates-internal-js" */)
}

